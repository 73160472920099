// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/images/about-img.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about__section {
  height: 743px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
@media (max-width: 1001px) {
  .about__section {
    height: 196px;
    background-position: center;
    background-size: cover;
  }
}
@media (min-width: 1001px) and (max-width: 1550px) {
  .about__section {
    height: 400px;
  }
}
.about__title-second {
  padding-top: 500px;
}
@media (max-width: 1001px) {
  .about__title-second {
    padding-top: 140px;
  }
}
@media (min-width: 1001px) and (max-width: 1550px) {
  .about__title-second {
    padding-top: 300px;
  }
}
.about__article {
  position: relative;
  text-align: start;
  padding-top: 100px;
  padding-bottom: 150px;
}
@media (max-width: 1001px) {
  .about__article {
    padding-top: 30px;
    padding-bottom: 45px;
  }
}
.about-phone {
  position: absolute;
  bottom: 100px;
  right: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/AboutPage/aboutPage.scss","webpack://./src/styles/utils/_mixins.scss"],"names":[],"mappings":"AAGE;EACE,aAAA;EACA,yDAAA;AAFJ;ACFE;EDEA;IAMI,aAAA;IACA,2BAAA;IACA,sBAAA;EAFJ;AACF;ACHE;EDJA;IAYI,aAAA;EADJ;AACF;AAKI;EACE,kBAAA;AAHN;ACjBE;EDmBE;IAII,kBAAA;EAFN;AACF;AChBE;EDaE;IAQI,kBAAA;EADN;AACF;AAKE;EACE,kBAAA;EACA,iBAAA;EACA,kBAAA;EACA,qBAAA;AAHJ;ACjCE;EDgCA;IAOI,iBAAA;IACA,oBAAA;EAFJ;AACF;AAKE;EACE,kBAAA;EACA,aAAA;EACA,QAAA;AAHJ","sourcesContent":["@import '../../styles/utils/mixins';\r\n\r\n.about {\r\n  &__section {\r\n    height: 743px;\r\n    background-image: url(\"../../../public/images/about-img.png\");\r\n\r\n\r\n    @include onPhone {\r\n      height: 196px;\r\n      background-position: center;\r\n      background-size: cover;\r\n    }\r\n\r\n    @include onTablet {\r\n      height: 400px;\r\n    }\r\n  }\r\n\r\n  &__title {\r\n    &-second {\r\n      padding-top: 500px;\r\n\r\n      @include onPhone {\r\n        padding-top: 140px;\r\n      }\r\n\r\n      @include onTablet {\r\n        padding-top: 300px;\r\n      }\r\n    }\r\n  }\r\n\r\n  &__article {\r\n    position: relative;\r\n    text-align: start;\r\n    padding-top: 100px;\r\n    padding-bottom: 150px;\r\n\r\n    @include onPhone {\r\n      padding-top: 30px;\r\n      padding-bottom: 45px;\r\n    }\r\n  }\r\n\r\n  &-phone {\r\n    position: absolute;\r\n    bottom: 100px;\r\n    right: 0;\r\n    // right: 20px;\r\n  }\r\n}\r\n","@mixin onPhone {\r\n  @media (max-width: 1001px) {\r\n    @content;\r\n  }\r\n}\r\n\r\n@mixin onTablet {\r\n  @media (min-width: 1001px) and (max-width: 1550px) {\r\n    @content;\r\n  }\r\n}\r\n\r\n@mixin onDesktop {\r\n  @media (min-width: 1001px) {\r\n    @content;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
