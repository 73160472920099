// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/images/contacts-img.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contacts {
  padding-bottom: 88px;
}
.contacts__header {
  height: 694px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
@media (max-width: 1001px) {
  .contacts__header {
    height: 200px;
  }
}
@media (min-width: 1001px) and (max-width: 1550px) {
  .contacts__header {
    height: 400px;
  }
}
.contacts__header-container {
  margin: auto;
  max-width: 1490px;
}
@media (max-width: 1001px) {
  .contacts__header-container {
    margin: 0 15px;
  }
}
@media (min-width: 1001px) and (max-width: 1550px) {
  .contacts__header-container {
    margin: 0 100px;
  }
}
.contacts__title {
  padding-top: 438px;
}
@media (max-width: 1001px) {
  .contacts__title {
    padding-top: 140px;
  }
}
@media (min-width: 1001px) and (max-width: 1550px) {
  .contacts__title {
    padding-top: 300px;
  }
}

input[type=text]::-webkit-outer-spin-button,
input[type=text]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=text] {
  -moz-appearance: textfield;
}`, "",{"version":3,"sources":["webpack://./src/components/ContactsPage/contacts.scss","webpack://./src/styles/utils/_mixins.scss"],"names":[],"mappings":"AAEA;EACE,oBAAA;AADF;AAGE;EACE,aAAA;EACA,yDAAA;AADJ;ACLE;EDIA;IAKI,aAAA;EAAJ;AACF;ACJE;EDFA;IASI,aAAA;EACJ;AACF;AACI;EACE,YAAA;EACA,iBAAA;AACN;ACnBE;EDgBE;IAKI,cAAA;EAEN;AACF;AClBE;EDUE;IASI,eAAA;EAGN;AACF;AACE;EACE,kBAAA;AACJ;AChCE;ED8BA;IAII,kBAAA;EAEJ;AACF;AC/BE;EDwBA;IAQI,kBAAA;EAGJ;AACF;;AACA;;EAEE,wBAAA;EACA,SAAA;AAEF;;AACA;EACE,0BAAA;AAEF","sourcesContent":["@import \"../../styles/utils/mixins\";\r\n\r\n.contacts {\r\n  padding-bottom: 88px;\r\n\r\n  &__header {\r\n    height: 694px;\r\n    background-image: url(\"../../../public/images/contacts-img.png\");\r\n\r\n    @include onPhone {\r\n      height: 200px;\r\n    }\r\n\r\n    @include onTablet {\r\n      height: 400px;\r\n    }\r\n\r\n    &-container {\r\n      margin: auto;\r\n      max-width: 1490px;\r\n\r\n      @include onPhone {\r\n        margin: 0 15px;\r\n      }\r\n\r\n      @include onTablet {\r\n        margin: 0 100px;\r\n      }\r\n    }\r\n  }\r\n\r\n  &__title {\r\n    padding-top: 438px;\r\n\r\n    @include onPhone {\r\n      padding-top: 140px;\r\n    }\r\n\r\n    @include onTablet {\r\n      padding-top: 300px;\r\n    }\r\n  }\r\n}\r\n\r\ninput[type=\"text\"]::-webkit-outer-spin-button,\r\ninput[type=\"text\"]::-webkit-inner-spin-button {\r\n  -webkit-appearance: none;\r\n  margin: 0;\r\n}\r\n\r\ninput[type=\"text\"] {\r\n  -moz-appearance: textfield;\r\n}\r\n","@mixin onPhone {\r\n  @media (max-width: 1001px) {\r\n    @content;\r\n  }\r\n}\r\n\r\n@mixin onTablet {\r\n  @media (min-width: 1001px) and (max-width: 1550px) {\r\n    @content;\r\n  }\r\n}\r\n\r\n@mixin onDesktop {\r\n  @media (min-width: 1001px) {\r\n    @content;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
