// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.review__card {
  height: 294px;
}
@media (min-width: 1001px) {
  .review__card {
    height: 400px;
  }
}
@media (min-width: 1001px) and (max-width: 1550px) {
  .review__card {
    height: 294px;
  }
}
.review__card-img {
  width: 100%;
}
.review__card__title {
  font-size: 18px;
  font-weight: 700;
  line-height: 25.2px;
  text-align: left;
}
.review__card__subtitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  color: #6E9BB8;
}`, "",{"version":3,"sources":["webpack://./src/components/ReviewCard/review.scss","webpack://./src/styles/utils/_mixins.scss"],"names":[],"mappings":"AAGE;EACE,aAAA;AAFJ;ACWE;EDVA;IAII,aAAA;EADJ;AACF;ACAE;EDJA;IAQI,aAAA;EAAJ;AACF;AAEI;EACE,WAAA;AAAN;AAGI;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;AADN;AAII;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;EACA,cAAA;AAFN","sourcesContent":["@import '../../styles/utils/mixins';\r\n\r\n.review {\r\n  &__card {\r\n    height: 294px;\r\n\r\n    @include onDesktop {\r\n      height: 400px;\r\n    }\r\n\r\n    @include onTablet {\r\n      height: 294px;\r\n    }\r\n\r\n    &-img {\r\n      width: 100%;\r\n    }\r\n\r\n    &__title {\r\n      font-size: 18px;\r\n      font-weight: 700;\r\n      line-height: 25.2px;\r\n      text-align: left;\r\n    }\r\n\r\n    &__subtitle {\r\n      font-size: 16px;\r\n      font-weight: 400;\r\n      line-height: 22.4px;\r\n      text-align: left;\r\n      color: #6E9BB8;\r\n    }\r\n  }\r\n}\r\n","@mixin onPhone {\r\n  @media (max-width: 1001px) {\r\n    @content;\r\n  }\r\n}\r\n\r\n@mixin onTablet {\r\n  @media (min-width: 1001px) and (max-width: 1550px) {\r\n    @content;\r\n  }\r\n}\r\n\r\n@mixin onDesktop {\r\n  @media (min-width: 1001px) {\r\n    @content;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
