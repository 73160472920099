// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product__card {
  border-radius: 10px;
  height: 345px;
  width: 365px;
  box-shadow: 4px 4px 11.4px 0px rgba(10, 61, 120, 0.2509803922);
}
@media (min-width: 1001px) and (max-width: 1550px) {
  .product__card {
    height: 275px;
    width: 295px;
  }
}
.product__title {
  padding: 15px;
  padding-left: 20px;
  text-align: start;
}
.product-img {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 272px;
}
@media (min-width: 1001px) and (max-width: 1550px) {
  .product-img {
    height: 205px;
  }
}
.product__link {
  text-decoration: none;
  color: #121212;
}`, "",{"version":3,"sources":["webpack://./src/components/ProductCard/product.scss","webpack://./src/styles/utils/_mixins.scss"],"names":[],"mappings":"AAGE;EACE,mBAAA;EACA,aAAA;EACA,YAAA;EACA,8DAAA;AAFJ;ACEE;EDJA;IAOI,aAAA;IACA,YAAA;EADJ;AACF;AAIE;EACE,aAAA;EACA,kBAAA;EACA,iBAAA;AAFJ;AAKE;EACE,UAAA;EACA,SAAA;EACA,WAAA;EACA,aAAA;AAHJ;ACfE;EDcA;IAOI,aAAA;EAFJ;AACF;AAKE;EACE,qBAAA;EACA,cAAA;AAHJ","sourcesContent":["@import '../../styles/utils/mixins';\r\n\r\n.product {\r\n  &__card {\r\n    border-radius: 10px;\r\n    height: 345px;\r\n    width: 365px;\r\n    box-shadow: 4px 4px 11.4px 0px #0a3d7840;\r\n\r\n    @include onTablet {\r\n      height: 275px;\r\n      width: 295px;\r\n    }\r\n  }\r\n\r\n  &__title {\r\n    padding: 15px;\r\n    padding-left: 20px;\r\n    text-align: start;\r\n  }\r\n\r\n  &-img {\r\n    padding: 0;\r\n    margin: 0;\r\n    width: 100%;\r\n    height: 272px;\r\n\r\n    @include onTablet {\r\n      height: 205px;\r\n    }\r\n  }\r\n\r\n  &__link {\r\n    text-decoration: none;\r\n    color: #121212;\r\n  }\r\n}\r\n","@mixin onPhone {\r\n  @media (max-width: 1001px) {\r\n    @content;\r\n  }\r\n}\r\n\r\n@mixin onTablet {\r\n  @media (min-width: 1001px) and (max-width: 1550px) {\r\n    @content;\r\n  }\r\n}\r\n\r\n@mixin onDesktop {\r\n  @media (min-width: 1001px) {\r\n    @content;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
