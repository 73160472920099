// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/images/about-img.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product__header {
  height: 743px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
@media (max-width: 1001px) {
  .product__header {
    height: 200px;
    background-size: cover;
  }
}
@media (min-width: 1001px) and (max-width: 1550px) {
  .product__header {
    height: 400px;
  }
}
.product__main-title {
  padding-top: 500px;
}
@media (max-width: 1001px) {
  .product__main-title {
    padding-top: 140px;
  }
}
@media (min-width: 1001px) and (max-width: 1550px) {
  .product__main-title {
    padding-top: 300px;
  }
}
.product-container {
  padding-top: 71px;
  padding-bottom: 122px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
@media (min-width: 1001px) and (max-width: 1550px) {
  .product-container {
    margin: auto;
    width: 910px;
    justify-content: start;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ProductPage/productPage.scss","webpack://./src/styles/utils/_mixins.scss"],"names":[],"mappings":"AAGE;EACE,aAAA;EACA,yDAAA;AAFJ;ACFE;EDEA;IAKI,aAAA;IACA,sBAAA;EADJ;AACF;ACFE;EDJA;IAUI,aAAA;EAAJ;AACF;AAGE;EACE,kBAAA;AADJ;AChBE;EDgBA;IAII,kBAAA;EAAJ;AACF;ACfE;EDUA;IAQI,kBAAA;EACJ;AACF;AAEE;EACE,iBAAA;EACA,qBAAA;EACA,aAAA;EACA,SAAA;EACA,eAAA;AAAJ;AC3BE;EDsBA;IAQI,YAAA;IACA,YAAA;IACA,sBAAA;EACJ;AACF","sourcesContent":["@import '../../styles/utils/mixins';\r\n\r\n.product {\r\n  &__header {\r\n    height: 743px;\r\n    background-image: url('../../../public/images/about-img.png');\r\n\r\n    @include onPhone {\r\n      height: 200px;\r\n      background-size: cover;\r\n    }\r\n\r\n    @include onTablet {\r\n      height: 400px;\r\n    }\r\n  }\r\n\r\n  &__main-title {\r\n    padding-top: 500px;\r\n\r\n    @include onPhone {\r\n      padding-top: 140px;\r\n    }\r\n\r\n    @include onTablet {\r\n      padding-top: 300px;\r\n    }\r\n  }\r\n\r\n  &-container {\r\n    padding-top: 71px;\r\n    padding-bottom: 122px;\r\n    display: flex;\r\n    gap: 10px;\r\n    flex-wrap: wrap;\r\n\r\n    @include onTablet {\r\n      margin: auto;\r\n      width: 910px;\r\n      justify-content: start;\r\n    }\r\n  }\r\n}","@mixin onPhone {\r\n  @media (max-width: 1001px) {\r\n    @content;\r\n  }\r\n}\r\n\r\n@mixin onTablet {\r\n  @media (min-width: 1001px) and (max-width: 1550px) {\r\n    @content;\r\n  }\r\n}\r\n\r\n@mixin onDesktop {\r\n  @media (min-width: 1001px) {\r\n    @content;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
